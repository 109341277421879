import React from "react";
import {Button, Card, IconButton, Stack, useTheme} from "@mui/material";
import {convert, Instant} from "@js-joda/core";
import {
	CalendarPlus01Icon,
	DotsVerticalIcon,
	SbChip,
	SbLicensePlate,
	SbTypography,
	SbTypographyRaw, Share07Icon, typographyBodyLarge,
	typographyBodySmall, typographyLabel, typographyTitleMedium,
	typographyTitleSmall,
	User01Icon
} from "@surebase/shared-component-library";

export interface SbNewsFeedProps {
	readonly items?: NewsFeedItem[];
}

export function SbNewsFeed(props: SbNewsFeedProps) {
	const items = props.items ?? [
		newsFeedItem(0),
		newsFeedItem(1),
		newsFeedItem(2),
		newsFeedItem(3),
	];
	
	return <Stack gap={2} style={{overflowY: "auto", paddingBottom: "200px"}}>
		{items.map((item, index) => {
			return <SbNewsFeedItem key={index} item={item}/>;
		})}
	</Stack>;
}

function SbNewsFeedItem(props: { item: NewsFeedItem }) {
	const theme = useTheme();
	
	const iconColor = theme.palette.text.secondary;
	
	const item = props.item;
	return <Stack direction={"row"} style={{minHeight: "210px"}}>
		<div style={{width: "8px", backgroundColor: getBorderColor(item)}}/>
		<Card style={{flexGrow: 1, borderRadius: "0 16px 16px 0"}}>
			<Stack gap={2}>
				<Stack direction={"row"} gap={3} alignItems={"center"}
					style={{height: "69px", borderBottom: "1px solid rgba(0, 0, 0, 0.04)", padding: "16px 24px"}}>
					<div>
						<SbTypographyRaw {...typographyTitleSmall}>{item.name}</SbTypographyRaw>
						<SbTypographyRaw {...typographyBodySmall}
							color={"passive"}>{formatInstant(item.moment)}</SbTypographyRaw>
					</div>
					<SbChip
						variant={"soft"}
						color={getChipColor(item)}
						size={"small"}
					>
						<SbTypography {...typographyLabel} color={"inherit"}>{getChipText(item)}</SbTypography>
					</SbChip>
					<div style={{flexGrow: 1}}/>
					<IconButton>
						<DotsVerticalIcon/>
					</IconButton>
				</Stack>
				
				<Stack gap={1} style={{padding: "0 24px"}}>
					<Stack direction={"row"} gap={1}>
						
						<SbTypography {...typographyTitleMedium}>{getTypeText(item)}</SbTypography>
						{item.licensePlate && <div>
							<SbLicensePlate licensePlate={item.licensePlate}/>
						</div>}
					</Stack>
					<SbTypographyRaw {...typographyBodyLarge}
						color={theme => theme.palette.text.secondary}>{item.primaryAttributes.join(" • ")}</SbTypographyRaw>
				</Stack>
				
				<Stack direction={"row"} style={{padding: "0 24px"}} justifyContent={"space-between"}>
					<Stack direction={"row"} gap={3}>
						{item.relatedEntities.map((entity, index) => {
							return <img alt={"SureBase"} height={34} key={index} src={entity.iconUrl}/>;
						})}
					</Stack>
					<Stack direction={"row"} gap={2}>
						<Button startIcon={<User01Icon color={iconColor}/>}>
							<SbTypography {...typographyLabel}
								color={theme => theme.palette.text.secondary}>Followers</SbTypography>
						</Button>
						<Button startIcon={<CalendarPlus01Icon color={iconColor}/>}>
							<SbTypography {...typographyLabel} color={theme => theme.palette.text.secondary}>Add to
								agenda</SbTypography>
						</Button>
						<Button startIcon={<Share07Icon color={iconColor}/>}>
							<SbTypography {...typographyLabel}
								color={theme => theme.palette.text.secondary}>Share</SbTypography>
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Card>
	</Stack>;
}

function formatInstant(value: Instant) {
	const jsDate = convert(value).toDate();
	
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit"
	};
	
	// const locale = userPreferencesProvider.userPreferences.localization.language.key;
	const locale = "nl-NL";
	return jsDate.toLocaleDateString(locale, options);
}

function getBorderColor(item: NewsFeedItem) {
	switch (item.status) {
		case "cancellation":
			return "#F04438";
		case "new":
		default:
			return "#16B364";
	}
}

function getChipColor(item: NewsFeedItem) {
	switch (item.status) {
		case "cancellation":
			return "error";
		case "new":
		default:
			return "success";
	}
}

function getChipText(item: NewsFeedItem) {
	switch (item.status) {
		case "cancellation":
			return "Cancellation";
		case "new":
		default:
			return "New";
	}
}

function getTypeText(item: NewsFeedItem) {
	switch (item.type) {
		case "energy-contract":
			return "Energy contract";
		case "car-insurance":
		default:
			return "Car insurance";
	}
}

interface NewsFeedItem {
	readonly name: string;
	readonly moment: Instant;
	readonly status: "new" | "cancellation";
	readonly type: "car-insurance" | "energy-contract";
	readonly licensePlate?: string;
	readonly primaryAttributes: string[];
	readonly relatedEntities: NewsFeedRelatedEntity[];
}

interface NewsFeedRelatedEntity {
	readonly iconUrl: string;
}

function newsFeedItem(index: number): NewsFeedItem {
	return {
		name: "Harm Volmuller",
		moment: Instant.parse("2023-08-12T14:35:00Z"),
		status: index === 1 ? "cancellation" : "new",
		type: index % 1 === 0 ? "car-insurance" : "energy-contract",
		licensePlate: index === 0 ? "rb-169-zn" : index === 2 ? "01-htc-8" : undefined,
		primaryAttributes: index % 1 === 0 ? ["Volvo XC40", "Allianz Direct"] : ["5557755", "Kortgerecht 38, Schoonrewoerd"],
		relatedEntities:
			index === 0
				? [
					{iconUrl: "https://s3-eu-west-1.amazonaws.com/tussenpersonen/productie/logos/13100/Polis.png"},
					{iconUrl: "https://compare.risk.nl/WebModule/IMG/maatschappijen/A015_small.png"},
				]
				: index === 1
					? [
						{iconUrl: "https://s3-eu-west-1.amazonaws.com/tussenpersonen/productie/logos/15760/Polis.png"},
						{iconUrl: "https://compare.risk.nl/WebModule/IMG/maatschappijen/A059B_small.png"},
					]
					: index === 2
						? [
							{iconUrl: "https://s3-eu-west-1.amazonaws.com/tussenpersonen/productie/logos/00010/Polis.png"},
							{iconUrl: "https://compare.risk.nl/WebModule/IMG/maatschappijen/R515_small.png"},
						]
						: [
							{iconUrl: "https://s3-eu-west-1.amazonaws.com/tussenpersonen/productie/logos/12000/Polis.png"},
							{iconUrl: "https://compare.risk.nl/WebModule/IMG/maatschappijen/R021_small.png"},
						]
	};
}
