import {Button, Card, Stack} from "@mui/material";
import React, {CSSProperties} from "react";
import {NavigationAction} from "../../models/calltoaction/NavigationAction";
import {CallToAction} from "../../models/calltoaction/CallToAction";
import {
	fullSize,
	SbLinkButton,
	SbTypography,
	palette, fullHeight, Users02Icon, ClipboardIcon, File05Icon, PlusCircleIcon
} from "@surebase/shared-component-library";

export interface SbCallToActionProps {
	readonly data: CallToAction;
}

// todo generalize
export function SbCallToAction(props: SbCallToActionProps) {
	const baseStyle: CSSProperties = {
		borderRadius: "8px",
		width: "262px",
		height: "52px",
	};
	
	if (props.data.type === "navigation") {
		const action = props.data as NavigationAction;
		return <Card style={baseStyle}>
			<SbLinkButton
				to={action.url}
				ButtonProps={{
					style: {
						...fullSize,
						borderRadius: "inherit",
						padding: "12px 24px",
						gap: "8px",
						justifyContent: "start",
					}
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				><SbCallToActionIcon data={props.data}/></div>
				<SbTypography style={{fontWeight: "bold"}} fontFamily={"jakarta"} color={theme => theme.palette.text.primary}>{props.data.name}</SbTypography>
			</SbLinkButton>
		</Card>;
	}
	
	return <Card style={baseStyle}>
		<Stack direction={"row"} alignItems={"center"} style={{...fullSize, padding: "0 0 0 16px"}}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			><SbCallToActionIcon data={props.data}/></div>
			<SbTypography style={{fontWeight: "bold"}} fontFamily={"jakarta"}>{props.data.name}</SbTypography>
		</Stack>
	</Card>;
}

function SbCallToActionIcon(props: { data: CallToAction }) {
	if (props.data.type === "navigation") {
		const action = props.data as NavigationAction;
		switch (action.url) {
			case "/customers": return <Users02Icon/>;
			case "/contracts": return <File05Icon/>;
			case "/claims": return <ClipboardIcon/>;
		}
	}
	return <Users02Icon/>;
}

export function SbCallToActionPlaceholder() {
	return <Button style={{
		width: "262px",
		height: "52px",
		borderRadius: "8px",
		border: "1px dashed var(--secondary-outline-border, rgba(56, 66, 80, 0.50))",
		color: palette.passive.main
	}}>
		<Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"} style={{...fullHeight}}>
			<PlusCircleIcon color={"rgba(56, 66, 80, 0.50)"}/>
		</Stack>
	</Button>;
}
