import {Stack} from "@mui/material";
import {
	authorizedByLogin,
	generalError,
	SbBreadcrumbs,
	SbSettings
} from "@surebase/shared-component-library";
import React from "react";
import {ErrorBoundary} from "react-error-boundary";

export const SettingsPage = authorizedByLogin(() => {
	return <Stack gap={3} alignItems={"start"} paddingBottom={"20vh"}>
		<SbBreadcrumbs/>
		<ErrorBoundary FallbackComponent={generalError}>
			<SbSettings/>
		</ErrorBoundary>
	</Stack>;
});
