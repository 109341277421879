import React from "react";
import {DotsVerticalIcon, fullHeight, SbTypography} from "@surebase/shared-component-library";
import {CallToAction} from "../models/calltoaction/CallToAction";
import {NavigationAction} from "../models/calltoaction/NavigationAction";
import {SbCallToAction, SbCallToActionPlaceholder} from "../components/common/SbCallToAction";
import {SbAutoGrid} from "../components/common/SbAutoGrid";
import {IconButton, Stack} from "@mui/material";
import {SbNewsFeed} from "../components/common/SbNewsFeed";

export function HomePage() {
	const callToActions: CallToAction[] = [
		{
			type: "navigation",
			name: "Customers",
			url: "/customers"
		} as NavigationAction,
		{
			type: "navigation",
			name: "Contracts",
			url: "/contracts"
		} as NavigationAction,
		{
			type: "navigation",
			name: "Claims",
			url: "/claims"
		} as NavigationAction,
	];
	
	return <div style={{...fullHeight, paddingBottom: "30px"}}>
		<Stack gap={3} style={{...fullHeight}}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<SbTypography variant={"header2"}>Favorites</SbTypography>
				<IconButton>
					<DotsVerticalIcon/>
				</IconButton>
			</Stack>
			<SbAutoGrid>
				{callToActions.map((it, index) => <SbCallToAction key={index} data={it}/>)}
				<SbCallToActionPlaceholder/>
				<SbCallToActionPlaceholder/>
			</SbAutoGrid>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<SbTypography variant={"header2"}>News feed</SbTypography>
				<IconButton>
					<DotsVerticalIcon/>
				</IconButton>
			</Stack>
			<SbNewsFeed/>
		</Stack>
	</div>;
}
