import React, {ReactNode} from "react";
import {Stack} from "@mui/material";

export interface SbAutoGridProps {
	readonly children: ReactNode[];
}

export function SbAutoGrid(props: SbAutoGridProps) {
	return <Stack direction={"row"} gap={"32px"} flexWrap={"wrap"}>
		{props.children}
	</Stack>;
}
